import React from "react";
import PropTypes from "prop-types";

class CustomReportTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getReports(){
    return [
      {name:"Hotel Report", url:"/admin/reports/hotel_report"},
      {name:"Exec Registration Report", url:"/admin/reports/exec_registration_report"},
      {name:"Ticketing Report", url:"/admin/reports/ticketing_report"}
      // {name:"Report Template", url:"/admin/reports/report_template"}
    ]
  }

  renderReports() {
    return this.getReports().map(x=>
      this.renderReport(x)
    );
  }

  renderReport(report) {
    return (
      <div className="sg-admin-custom-report-container">
        <a
          href={report.url}
          target="_blank"
          className="sg-admin-custom-report-button"
        >
          <img src="/images/admin/custom-report-download.png"/>
        </a>
        <div className="sg-admin-custom-report-name">
          <a href={report.url} target="_blank">
            {report.name}
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sg-admin-custom-report-table">
        {this.renderReports()}
      </div>
    )
  }

}

export default CustomReportTable;
